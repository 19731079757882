import {CUSTOM_DIMENSION_MAP, UA_ID, GA4_ID} from './constants';
import {getConfigProps} from './meta/index';
import {toLowerCase} from './meta/utils';

interface WindowGtag extends Window {
  // tslint:disable-next-line: no-any
  newzitDataLayer?: any;
  // tslint:disable-next-line: no-any
  gtag?(...args: any[]): void;
}

// This needs to be like this
// tslint:disable-next-line: only-arrow-functions
const gtag: WindowGtag['gtag'] = function() {
  // eslint-disable-next-line prefer-rest-params
  (window as WindowGtag).newzitDataLayer.push(arguments);
};

const loadGtag = () => {
  (window as WindowGtag).newzitDataLayer = (window as WindowGtag).newzitDataLayer || [];
  (window as WindowGtag).gtag = gtag;
  gtag('js', new Date());
};

let initialisedConfig = false;

const setConfigIfNeeded = () => {
  if (initialisedConfig) {
    return;
  }

  loadGtag();

  const props = toLowerCase(getConfigProps());

  gtag('config', UA_ID, {
    // eslint-disable-next-line id-match
    custom_map: CUSTOM_DIMENSION_MAP,
    // eslint-disable-next-line id-match
    send_page_view: false,
    ...props
  });

  gtag('config', GA4_ID, {
    send_page_view: false,
    ...props
  });

  initialisedConfig = true;
};

interface Props {
  [key: string]: string | number | boolean | undefined;
}

export const pageView = (props: Props) => {
  setConfigIfNeeded();
  gtag('event', 'page_view', {
    ...toLowerCase(props),
    send_to: [UA_ID, GA4_ID]
  });
};

const MAX_EVENT_NAME_LENGTH = 40;

export const event = (action: string, props: Props, ga4EventName?: string) => {
  setConfigIfNeeded();

  const eventProps = toLowerCase({
    ...props,

    // Prevent carrying over metric increase from pageview inherited value
    articleViewIncrease: undefined
  });

  gtag('event', action, {
    eventProps,
    send_to: UA_ID
  });

  let extendedEventName = ga4EventName;

  if (!extendedEventName) {
    const eventNamePrefix = (props && props.event_category) || 'unknown';

    extendedEventName = `${eventNamePrefix}_${action}`.replace(/\s/g, '_');

    if (extendedEventName.length > MAX_EVENT_NAME_LENGTH) {
      extendedEventName = extendedEventName.substring(0, MAX_EVENT_NAME_LENGTH);
    }
  }

  if (extendedEventName) {
    gtag('event', extendedEventName, {
      eventProps,
      send_to: GA4_ID
    });
  }
};
